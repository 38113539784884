@tailwind base;
@tailwind components;
@tailwind utilities;

#mission {
    background-color: var(--gray);
}

#missionTitle {
    color: var(--dark-green);
}

#missionContent {
    color: black;
}

#optometryPartner {
    background-color: var(--beige);
}

#optometryPartnerText {
    color: var(--dark-green);
    text-align: center;
}

#serviceCards {
    background-color: var(--gray);
}

#visitUs {
    background-color: var(--dark-green);
    position: relative;
}

#visitUsText {
    color: white;
    text-align: center;
    margin: auto;
}

.cardTitle,
.cardText {
    color: var(--dark-green);
}

.cardButtons {
    background-color: var(--dark-green);
    color: white;
    font-family: poppins;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

#ourTeam {
    background-color: var(--gray);
}

#ourTeamTitle {
    color: var(--dark-green);
}

#ourTeamContent {
    color: black;
}

#doctorBackground {
    background-color: var(--gray);
}

.doctorImage {
    border-radius: 50%;
}

.doctorName {
    color: var(--dark-green);
}

.doctorBio {
    color: black;
}
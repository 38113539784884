@tailwind base;
@tailwind components;
@tailwind utilities;

#insuranceplanbg {
    background-color: var(--dark-green);
}

#listcontainer {
    width: 70%;
}

.insurancebg {
    /* background-color: white; */
    mix-blend-mode: multiply;
    width: auto;
    height:  15vh;
}

.bulletList {
    list-style-type: circle;
    margin-left: 2rem
}

.numberList {
    list-style-type: decimal;
    margin-left: 2rem
}

.faqBorder {
    border: 4px solid var(--dark-green);
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.servicesBackground {
    background-color: var(--gray);
}

.serviceName {
    color: var(--dark-green);
}

.serviceButtons > p {
    color: var(--dark-green);
}

.invertdropdown {
    transition: 0.25s ease-in;
}
.invertdropdown:hover {
    width: 100%;
    filter: invert(0.5)
}

.faqHide {
    display: None
}

.faqShow {
    display: block;
}

.insuranceContainer {
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.insuranceContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#carouselSection {
    background-color: var(--beige);
    overflow: hidden;
}

.btn-reviews {
    background-color: var(--beige) !important;
    border-color: var(--beige) !important;
    color: black !important;
}

.btn-reviews:hover {
    background-color: var(--beige);
    border-color: var(--beige);
    font-size: larger;
}

#customerReviews {
    color: #565656;
}

.carousel-item > p {
    color: #565656;
}

.carousel-item {
    overflow: hidden;
}

#carouselIndicators > a {
    border-color: #CCCCCC;
    background-color: #CCCCCC;
    color: #CCCCCC;
}

a.reviewActive {
    background-color: var(--dark-green) !important;
    color: var(--dark-green) !important;
}
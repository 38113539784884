body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color Palette */

:root {
  --dark-green: #014548;
  --light-green: #B5D699;
  --beige: #F9EDD6;
  --gray: #F5F5F5;
}
/* To use colors, use: */
/* background-color: var(--dark-green); */

.hind {
  font-family: 'Hind', sans-serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

/* Banner Portion */
.banner {
  background-color: var(--dark-green);
  color: white;
}

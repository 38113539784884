@tailwind base;
@tailwind components;
@tailwind utilities;

#informationBackground {
    background-color: var(--gray);
}

#exteriorBackground {
    background-color: var(--gray);
}

.informationTitle {
    color: var(--dark-green);
}

.informationContent {
    color: black;
}

.mapcontainer {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 50vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.header-img {
    height: 120px;
    width: auto;
}

.navbar-bg {
    background-color: var(--gray);
}

.header-div>p {
    color: var(--dark-green);
}

.navlink-header>p {
    color: var(--dark-green);
}

.banner-nav {
    color: var(--dark-green);
}

.badge {
    height: fit-content !important;
    transition: 0.66s ease-in;
    transition: delay
}

.badge:hover {
    transition: 0.66s ease-in;
    background-color: var(--dark-green);
}

.badge:not(:hover) {
    transition-delay: 0.66s;
    background-color: white;
}

.badge p {
    transition: color 0.66s ease-in;
}

.badge p:hover {
    color: white;
}

.badge-outline {
    color: var(--dark-green) !important;
    border-width: 0.25rem !important;
}

.bottom-nav {
    background-color: var(--light-green);
}

.headercontainer p {
    cursor: pointer;
    position: relative;
}

p.active::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: var(--dark-green);
    left: 0;
    bottom: -5px;
    animation: appear 1s linear;
}

@keyframes appear {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}


.hideMenuNav {
    display: none;
}

.showMenuNav {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
